import {

  SIGN_OUT,

  GET_POS_COMPANIES,
  CREATE_POS_COMPANY,
  UPDATE_POS_COMPANY,

  SHOW_CREATE_POS_COMPANY_FORM,
  HIDE_CREATE_POS_COMPANY_FORM,
  SHOW_UPDATE_POS_COMPANY_FORM,
  HIDE_UPDATE_POS_COMPANY_FORM,

} from "constants/ActionTypes";

const INIT_STATE = {
  posCompanies: {
    data: [],
    count: 0
  },
  isCreatePosCompanyFormVisible: false,
  posAgencyUpdating: {
    isUpdatePosCompanyFormVisible: false,
    currentUpdating: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_POS_COMPANIES: {
      return {
        ...state,
        posCompanies: {
          ...state.posCompanies,
          data: action.payload.data,
          count: action.payload.count
        },
      };
    }

    case CREATE_POS_COMPANY: {
      const newPosCompany = action.payload;

      return {
        ...state,
        posCompanies: {
          ...state.posCompanies,
          data: [
            newPosCompany,
            ...state.posCompanies.data
          ],
          count: state.posCompanies.count + 1
        }
      };
    }

    case UPDATE_POS_COMPANY: {
      const updatedPosCompany = action.payload;

      return {
        ...state,
        posCompanies: {
          ...state.posCompanies,
          data: state.posCompanies.data.map(
            posCompany => posCompany?.id === updatedPosCompany?.id ? updatedPosCompany : posCompany
          )
        }
      }
    }

    case SHOW_CREATE_POS_COMPANY_FORM: {
      return {
        ...state,
        isCreatePosCompanyFormVisible: true,
      };
    }

    case HIDE_CREATE_POS_COMPANY_FORM: {
      return {
        ...state,
        isCreatePosCompanyFormVisible: false,
      };
    }

    case SHOW_UPDATE_POS_COMPANY_FORM: {
      const posCompanyId = action.payload;

      const currentUpdatingPosCompany = state.posCompanies.data.find(posComp => posComp.id == posCompanyId);
      return {
        ...state,
        
        posAgencyUpdating: {
          isUpdatePosCompanyFormVisible: true,
          currentUpdating: currentUpdatingPosCompany,
        },
      };
    }

    case HIDE_UPDATE_POS_COMPANY_FORM: {
      return {
        ...state,
        
        posAgencyUpdating: {
          isUpdatePosCompanyFormVisible: false,
          currentUpdating: null,
        },
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}