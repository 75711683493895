import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_PAYMENT_ARRANGEMENT_INVOICES,
  GET_A_PAYMENT_ARRANGEMENT_INVOICE,
  GET_PENDING_PAYMENT_ARRANGEMENT_INVOICES,
  UPDATE_PAYMENT_ARRANGEMENT_INVOICE,
  SHOW_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG,
  HIDE_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG,
  SHOW_INVOICE_PAYMENT_DIALOG,
  HIDE_INVOICE_SETTLEMENT_DIALOG,
  SHOW_INVOICE_SETTLEMENT_DIALOG, 
  HIDE_INVOICE_PAYMENT_DIALOG,
  SHOW_REVIEW_RECEIPT_DIALOG,
  HIDE_REVIEW_RECEIPT_DIALOG,
} from 'constants/ActionTypes';
import axios from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/EvomErrorsUtils';

const paInvoiceBaseEndpoint = 'top-up-invoices';

export const getPaymentArrangementInvoices = (page, size, sort, filter) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`${paInvoiceBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`)
      .then(res => {
        if (res.data) {
          const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_PAYMENT_ARRANGEMENT_INVOICES, payload: { data: res.data, count: totalCount } });
        } else {
          console.log('Error****:', res);
          dispatch({ type: FETCH_ERROR, payload: res.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getPendingPaymentInvoices = (companyId, uuid) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`${paInvoiceBaseEndpoint}/pending-payment-invoices/${companyId}`)
      .then(res => {
        if (res.data) {
          const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_PENDING_PAYMENT_ARRANGEMENT_INVOICES, payload: { data: res.data } });
          showInvoiceSettlementDialog(uuid, res.data)
        } else {
          console.log('Error****:', res);
          dispatch({ type: FETCH_ERROR, payload: res.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getPaymentArrangementInvoiceDetails = invoiceUuid => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`top-up-invoices/${invoiceUuid}`)
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_A_PAYMENT_ARRANGEMENT_INVOICE, payload: { invoiceUuid, data } });
        } else {
          console.log('Error****: null data');
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(function (error) {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const settleAndPayPaymentArrangementInvoice = (invoiceUuid, settlementType, detail) => async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.patch(`top-up-invoices/execute-settlement`, { topUpInvoiceUuid: invoiceUuid, settlementType, detail, });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: UPDATE_PAYMENT_ARRANGEMENT_INVOICE, payload: res.data });
      dispatch(hideInvoiceSettlementAndPaymentDialog());
      toast.success('Crédito liberado e fatura paga');
    } catch (error) {
      console.log('Error****:', error);
      toast.error(formatErrorResponse(error));
      dispatch({ type: FETCH_ERROR });
    }
};

export const settlePaymentArrangementInvoice = invoiceUuid => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.patch(`top-up-invoices/${invoiceUuid}/release-credit`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: UPDATE_PAYMENT_ARRANGEMENT_INVOICE, payload: res.data });
    dispatch(hideInvoiceSettlementDialog());
    toast.success('Crédito liberado');
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};

export const payPaymentArrangementInvoice = (invoiceUuid, settlementType, detail) => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.patch(`top-up-invoices/pay`, { topUpInvoiceUuid: invoiceUuid, settlementType, detail, });
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: UPDATE_PAYMENT_ARRANGEMENT_INVOICE, payload: res.data });
    dispatch(hideInvoicePaymentDialog());
    toast.success('Fatura paga');
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};


export const sendReviewAttachmentReview = (topUpInvoiceUuid, receiptAttachmentReview) => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.patch(`top-up-invoices/review-receipt/${topUpInvoiceUuid}`, receiptAttachmentReview);
    dispatch({ type: UPDATE_PAYMENT_ARRANGEMENT_INVOICE, payload: res.data });
    dispatch({ type: FETCH_SUCCESS });
    toast.success('Comprovante avaliado com sucesso');
  } catch(error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
  dispatch(hideReviewReceiptDialog());
};


export const showInvoiceSettlementAndPaymentDialog = invoiceUuid => ({
  type: SHOW_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG,
  payload: invoiceUuid,
});

export const hideInvoiceSettlementAndPaymentDialog = () => ({
  type: HIDE_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG
});

export const showInvoiceSettlementDialog = (invoiceUuid, showDebitMessage) => ({
  type: SHOW_INVOICE_SETTLEMENT_DIALOG,
  payload: {invoiceUuid: invoiceUuid, showDebitMessage: showDebitMessage},
});

export const hideInvoiceSettlementDialog = () => ({
  type: HIDE_INVOICE_SETTLEMENT_DIALOG
});

export const showInvoicePaymentDialog = invoiceUuid => ({
  type: SHOW_INVOICE_PAYMENT_DIALOG,
  payload: invoiceUuid,
});

export const hideInvoicePaymentDialog = () => ({
  type: HIDE_INVOICE_PAYMENT_DIALOG
});

export const showReviewReceiptDialog = (invoiceUuid, optionals = {}) => ({
  type: SHOW_REVIEW_RECEIPT_DIALOG,
  payload: {invoiceUuid: invoiceUuid, optionals: optionals},
});

export const hideReviewReceiptDialog = () => ({
  type: HIDE_REVIEW_RECEIPT_DIALOG
});