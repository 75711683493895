import {
  SIGN_OUT,
  CLEAR_WALLET_PARAMETERIZATION_STATE,
  GET_WALLET_CONFIGS,
  UPDATE_WALLET_CONFIG,
  UPDATE_APP_WALLET_APPLICATION_STATUS,
  UPDATE_WALLET_CONFIG_ITEM,
  UPDATE_PARAMETERIZATION_TABLE_STATE,
  UPDATE_WALLET_CONFIG_ITEM_ATTACHMENT,
  UPDATE_WALLET_CONFIG_PAYMENT_ITEM
} from "../constants/ActionTypes";

const INIT_STATE = {
  initialWalletConfigs: [],
  walletConfigs: [],
  updatedWalletConfigs: {
    walletConfigs: {},
    walletConfigItems: {},
    walletConfigPaymentItems: {}
  },
  changesPerWallet: {},
  parameterizationTableState: {
    expandedRows: []
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case CLEAR_WALLET_PARAMETERIZATION_STATE: {
      return INIT_STATE;
    }

    case GET_WALLET_CONFIGS: {
      const initialWalletConfigs = action.payload.sort((a, b) => a.id - b.id);
      //deep copy do array inicial
      const walletConfigs = initialWalletConfigs.map(walletConfig =>
      ({
        ...walletConfig,
        items: walletConfig.items.map(item => ({ ...item })),
        paymentItems: walletConfig.paymentItems.map(paymentItem => ({ ...paymentItem }))
      })
      );

      return {
        ...state,
        initialWalletConfigs: initialWalletConfigs,
        walletConfigs: walletConfigs
      };
    }

    case UPDATE_WALLET_CONFIG: {
      const newWalletConfigs = [...state.walletConfigs];
      const newUpdatedWalletConfigs = { ...state.updatedWalletConfigs };
      let newChangesPerWallet = { ...state.changesPerWallet }
      const walletConfigNewData = action.payload;
      const initialConfig = state.initialWalletConfigs.find(wallet => wallet.id === walletConfigNewData.walletId);

      //alterar o walletConfigs
      newWalletConfigs.find(wallet => wallet.id === walletConfigNewData.walletId).active = walletConfigNewData.newValue;

      //alterar o updatedWalletConfigs
      newUpdatedWalletConfigs.walletConfigs[walletConfigNewData.walletId] = { active: walletConfigNewData.newValue };

      //alterar o changesPerWallet // só adicionar se for diferente
      if (initialConfig.active === walletConfigNewData.newValue) {
        //limpar o changesPerWallet
        const willBeRemovedIndex = newChangesPerWallet[walletConfigNewData.walletType].findIndex(change => change.typeOfChange === walletConfigNewData.typeOfChange && change.itemName === walletConfigNewData.itemName);
        newChangesPerWallet[walletConfigNewData.walletType] = newChangesPerWallet[walletConfigNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);
        if (newChangesPerWallet[walletConfigNewData.walletType].length === 0) {
          delete newChangesPerWallet[walletConfigNewData.walletType];
        }
      } else {
        //adicionar o changesPerWallet e criar o array caso n esteja criado
        if (newChangesPerWallet[walletConfigNewData.walletType]) {
          newChangesPerWallet[walletConfigNewData.walletType].push({ newValue: walletConfigNewData.newValue, itemName: walletConfigNewData.itemName, typeOfChange: walletConfigNewData.typeOfChange })
        } else {
          newChangesPerWallet[walletConfigNewData.walletType] = [{ newValue: walletConfigNewData.newValue, itemName: walletConfigNewData.itemName, typeOfChange: walletConfigNewData.typeOfChange }]
        }
      }

      //comparar com o estado inicial e limpar o updatedWalletConfigs
      if (initialConfig.active === walletConfigNewData.newValue) {
        // limpar o updatedWalletConfigs
        delete newUpdatedWalletConfigs.walletConfigs[walletConfigNewData.walletId];
      }

      return {
        ...state,
        walletConfigs: newWalletConfigs,
        updatedWalletConfigs: newUpdatedWalletConfigs,
        changesPerWallet: newChangesPerWallet,
      }
    }

    case UPDATE_APP_WALLET_APPLICATION_STATUS: {
      const newWalletConfigs = [...state.walletConfigs];
      const newUpdatedWalletConfigs = { ...state.updatedWalletConfigs };
      let newChangesPerWallet = { ...state.changesPerWallet }
      const walletConfigNewData = action.payload;
      const initialConfig = state.initialWalletConfigs.find(wallet => wallet.id === walletConfigNewData.walletId);

      //alterar o walletConfigs
      newWalletConfigs.find(wallet => wallet.id === walletConfigNewData.walletId).enableAppWalletApplication = walletConfigNewData.newValue;

      //alterar o updatedWalletConfigs
      newUpdatedWalletConfigs.walletConfigs[walletConfigNewData.walletId] = { enableAppWalletApplication: walletConfigNewData.newValue };

      //alterar o changesPerWallet // só adicionar se for diferente
      if (initialConfig.enableAppWalletApplication === walletConfigNewData.newValue) {
        //limpar o changesPerWallet
        const willBeRemovedIndex = newChangesPerWallet[walletConfigNewData.walletType].findIndex(change => change.typeOfChange === walletConfigNewData.typeOfChange && change.itemName === walletConfigNewData.itemName);
        newChangesPerWallet[walletConfigNewData.walletType] = newChangesPerWallet[walletConfigNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);
        if (newChangesPerWallet[walletConfigNewData.walletType].length === 0) {
          delete newChangesPerWallet[walletConfigNewData.walletType];
        }
      } else {
        //adicionar o changesPerWallet e criar o array caso n esteja criado
        if (newChangesPerWallet[walletConfigNewData.walletType]) {
          newChangesPerWallet[walletConfigNewData.walletType].push({ newValue: walletConfigNewData.newValue, itemName: walletConfigNewData.itemName, typeOfChange: walletConfigNewData.typeOfChange })
        } else {
          newChangesPerWallet[walletConfigNewData.walletType] = [{ newValue: walletConfigNewData.newValue, itemName: walletConfigNewData.itemName, typeOfChange: walletConfigNewData.typeOfChange }]
        }
      }

      //comparar com o estado inicial e limpar o updatedWalletConfigs
      if (initialConfig.enableAppWalletApplication === walletConfigNewData.newValue) {
        // limpar o updatedWalletConfigs
        delete newUpdatedWalletConfigs.walletConfigs[walletConfigNewData.walletId];
      }

      return {
        ...state,
        walletConfigs: newWalletConfigs,
        updatedWalletConfigs: newUpdatedWalletConfigs,
        changesPerWallet: newChangesPerWallet,
      }
    }

    case UPDATE_WALLET_CONFIG_ITEM: {
      const newWalletConfigs = [...state.walletConfigs];
      const newUpdatedWalletConfigs = { ...state.updatedWalletConfigs };
      let newChangesPerWallet = { ...state.changesPerWallet }
      const walletConfigItemNewData = action.payload;

      const initialItem = state.initialWalletConfigs.find(wallet => wallet.id === walletConfigItemNewData.walletId).items
        .find(walletConfigItem => walletConfigItem.id === walletConfigItemNewData.itemId);
      const item = newWalletConfigs.find(wallet => wallet.id === walletConfigItemNewData.walletId).items
        .find(walletConfigItem => walletConfigItem.id === walletConfigItemNewData.itemId);

      //alterar o walletConfigs
      item.active = walletConfigItemNewData.newValue;

      //attachment sempre vai pra false quando mudo o walletConfigItem
      item.attachment = false;

      //alterar o updatedWalletConfigs
      newUpdatedWalletConfigs.walletConfigItems[walletConfigItemNewData.itemId] = { active: walletConfigItemNewData.newValue, attachment: false };

      if (walletConfigItemNewData.newValue === false) {
        if (walletConfigItemNewData.attachment === true) {
          if (initialItem.attachment === false) {
            const willBeRemovedIndex = newChangesPerWallet[walletConfigItemNewData.walletType].findIndex(change => change.typeOfChange === 'walletConfigItemAttachment' && change.itemName === walletConfigItemNewData.itemName);
            newChangesPerWallet[walletConfigItemNewData.walletType] = newChangesPerWallet[walletConfigItemNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);

            if (newChangesPerWallet[walletConfigItemNewData.walletType].length === 0) {
              delete newChangesPerWallet[walletConfigItemNewData.walletType];
            }
          } else {
            if (newChangesPerWallet[walletConfigItemNewData.walletType]) {
              newChangesPerWallet[walletConfigItemNewData.walletType].push({ newValue: walletConfigItemNewData.newValue, itemName: walletConfigItemNewData.itemName, typeOfChange: 'walletConfigItemAttachment' })
            } else {
              newChangesPerWallet[walletConfigItemNewData.walletType] = [{ newValue: walletConfigItemNewData.newValue, itemName: walletConfigItemNewData.itemName, typeOfChange: 'walletConfigItemAttachment' }]
            }
          }
        }
      }
      //alterar o changesPerWallet // só adicionar se for diferente
      if (initialItem.active === walletConfigItemNewData.newValue) {
        //limpar o changesPerWallet
        const willBeRemovedIndex = newChangesPerWallet[walletConfigItemNewData.walletType].findIndex(change => change.typeOfChange === walletConfigItemNewData.typeOfChange && change.itemName === walletConfigItemNewData.itemName);
        newChangesPerWallet[walletConfigItemNewData.walletType] = newChangesPerWallet[walletConfigItemNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);
        if (newChangesPerWallet[walletConfigItemNewData.walletType].length === 0) {
          delete newChangesPerWallet[walletConfigItemNewData.walletType];
        }
      } else {
        //adicionar o changesPerWallet e criar o array caso n esteja criado
        if (newChangesPerWallet[walletConfigItemNewData.walletType]) {
          newChangesPerWallet[walletConfigItemNewData.walletType].push({ newValue: walletConfigItemNewData.newValue, itemName: walletConfigItemNewData.itemName, typeOfChange: walletConfigItemNewData.typeOfChange })
        } else {
          newChangesPerWallet[walletConfigItemNewData.walletType] = [{ newValue: walletConfigItemNewData.newValue, itemName: walletConfigItemNewData.itemName, typeOfChange: walletConfigItemNewData.typeOfChange }]
        }
      }

      //comparar com o estado inicial e limpar o updatedWalletConfigItems caso attachment e active sejam iguais aos iniciais
      if (initialItem.active === walletConfigItemNewData.newValue && initialItem.attachment === false) {
        delete newUpdatedWalletConfigs.walletConfigItems[walletConfigItemNewData.itemId];
      }

      return {
        ...state,
        walletConfigs: newWalletConfigs,
        updatedWalletConfigs: newUpdatedWalletConfigs,
        changesPerWallet: newChangesPerWallet,
      }
    }

    case UPDATE_WALLET_CONFIG_ITEM_ATTACHMENT: {
      const newWalletConfigs = [...state.walletConfigs];
      const newUpdatedWalletConfigs = { ...state.updatedWalletConfigs };
      let newChangesPerWallet = { ...state.changesPerWallet }
      const walletConfigItemAttachmentNewData = action.payload;

      const initialItem = state.initialWalletConfigs.find(wallet => wallet.id === walletConfigItemAttachmentNewData.walletId).items
        .find(walletConfigItem => walletConfigItem.id === walletConfigItemAttachmentNewData.itemId);
      const item = newWalletConfigs.find(wallet => wallet.id === walletConfigItemAttachmentNewData.walletId).items
        .find(walletConfigItem => walletConfigItem.id === walletConfigItemAttachmentNewData.itemId);

      //alterar o walletConfigs
      item.attachment = walletConfigItemAttachmentNewData.newValue;

      //alterar o updatedWalletConfigs
      newUpdatedWalletConfigs.walletConfigItems[walletConfigItemAttachmentNewData.itemId] = { active: true, attachment: walletConfigItemAttachmentNewData.newValue };

      //alterar o changesPerWallet // só adicionar se for diferente
      if (initialItem.attachment === walletConfigItemAttachmentNewData.newValue) {
        //limpar o changesPerWallet
        const willBeRemovedIndex = newChangesPerWallet[walletConfigItemAttachmentNewData.walletType].findIndex(change => change.typeOfChange === walletConfigItemAttachmentNewData.typeOfChange && change.itemName === walletConfigItemAttachmentNewData.itemName);
        newChangesPerWallet[walletConfigItemAttachmentNewData.walletType] = newChangesPerWallet[walletConfigItemAttachmentNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);
        if (newChangesPerWallet[walletConfigItemAttachmentNewData.walletType].length === 0) {
          delete newChangesPerWallet[walletConfigItemAttachmentNewData.walletType];
        }
      } else {
        //adicionar o changesPerWallet e criar o array caso n esteja criado
        if (newChangesPerWallet[walletConfigItemAttachmentNewData.walletType]) {
          newChangesPerWallet[walletConfigItemAttachmentNewData.walletType].push({ newValue: walletConfigItemAttachmentNewData.newValue, itemName: walletConfigItemAttachmentNewData.itemName, typeOfChange: walletConfigItemAttachmentNewData.typeOfChange })
        } else {
          newChangesPerWallet[walletConfigItemAttachmentNewData.walletType] = [{ newValue: walletConfigItemAttachmentNewData.newValue, itemName: walletConfigItemAttachmentNewData.itemName, typeOfChange: walletConfigItemAttachmentNewData.typeOfChange }]
        }
      }

      //comparar com o estado inicial e limpar o updatedWalletConfigItems caso attachment e active sejam iguais aos iniciais
      if (initialItem.active === true && initialItem.attachment === walletConfigItemAttachmentNewData.newValue) {
        delete newUpdatedWalletConfigs.walletConfigItems[walletConfigItemAttachmentNewData.itemId];
      }

      return {
        ...state,
        walletConfigs: newWalletConfigs,
        updatedWalletConfigs: newUpdatedWalletConfigs,
        changesPerWallet: newChangesPerWallet,
      }
    }

    case UPDATE_WALLET_CONFIG_PAYMENT_ITEM: {
      const newWalletConfigs = [...state.walletConfigs];
      const newUpdatedWalletConfigs = { ...state.updatedWalletConfigs };
      let newChangesPerWallet = { ...state.changesPerWallet }
      const paymentItemNewData = action.payload;
      const initialPaymentItem = state.initialWalletConfigs.find(wallet => wallet.id === paymentItemNewData.walletId).paymentItems.find(paymentItem => paymentItem.id === paymentItemNewData.paymentItemId);

      //atualizar o walletConfigs
      newWalletConfigs.find(wallet => wallet.id === paymentItemNewData.walletId).paymentItems.find(paymentItem => paymentItem.id === paymentItemNewData.paymentItemId).active = paymentItemNewData.newValue;

      //atualizar o updatedWalletConfigs
      newUpdatedWalletConfigs.walletConfigPaymentItems[paymentItemNewData.paymentItemId] = { active: paymentItemNewData.newValue };

      //atualizar o changesPerWallet // só adicionar se for diferente
      if (initialPaymentItem.active === paymentItemNewData.newValue) {
        //limpar o changesPerWallet
        const willBeRemovedIndex = newChangesPerWallet[paymentItemNewData.walletType].findIndex(change => change.typeOfChange === paymentItemNewData.typeOfChange && change.itemName === paymentItemNewData.itemName);
        newChangesPerWallet[paymentItemNewData.walletType] = newChangesPerWallet[paymentItemNewData.walletType].filter((change, index) => index !== willBeRemovedIndex);
        if (newChangesPerWallet[paymentItemNewData.walletType].length === 0) {
          delete newChangesPerWallet[paymentItemNewData.walletType];
        }
      } else {
        //adicionar o changesPerWallet e criar o array caso n esteja criado
        if (newChangesPerWallet[paymentItemNewData.walletType]) {
          newChangesPerWallet[paymentItemNewData.walletType].push({ newValue: paymentItemNewData.newValue, itemName: paymentItemNewData.itemName, typeOfChange: paymentItemNewData.typeOfChange })
        } else {
          newChangesPerWallet[paymentItemNewData.walletType] = [{ newValue: paymentItemNewData.newValue, itemName: paymentItemNewData.itemName, typeOfChange: paymentItemNewData.typeOfChange }]
        }
      }

      //comparar com o estado inicial e limpar o updatedWalletConfigs
      if (initialPaymentItem.active === paymentItemNewData.newValue) {
        // limpar o updatedWalletConfigs
        delete newUpdatedWalletConfigs.walletConfigPaymentItems[paymentItemNewData.paymentItemId];
      }

      return {
        ...state,
        walletConfigs: newWalletConfigs,
        updatedWalletConfigs: newUpdatedWalletConfigs,
        changesPerWallet: newChangesPerWallet,
      }
    }

    case UPDATE_PARAMETERIZATION_TABLE_STATE: {
      return {
        ...state,
        parameterizationTableState: {
          expandedRows: action.payload
        }
      };
    }

    default: {
      return state;
    }

  }
}
