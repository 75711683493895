import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  SHOW_UPDATE_COMPANY_FORM,
  SHOW_CREATE_COMPANY_FORM,
  SHOW_DELETE_COMPANY_DIALOG,
  HIDE_UPDATE_COMPANY_FORM,
  HIDE_CREATE_COMPANY_FORM,
  HIDE_DELETE_COMPANY_DIALOG,
  GET_COMPANY_SUMMARY,
  GET_TOP_UP_INVOICES_COUNT_BY_STATUS,
  GET_TOP_UP_INVOICES_COUNT_BY_DAY_AND_STATUS,
  CHECK_DUPLICATED_CNPJ,
  SHOW_DUPLICATED_CNPJ_DIALOG,
  HIDE_DUPLICATED_CNPJ_DIALOG,
} from 'constants/ActionTypes';
import axios from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/EvomErrorsUtils';

export const getCompanies = (page, size, sort, filter) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`companies?page=${page}&size=${size}&sort=${sort}&${filter}`)
      .then(res => {
        if (res.data) {
          const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_COMPANIES, payload: { data: res.data, count: totalCount } });
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const createCompany = newCompany => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.post('companies/custom', newCompany)
      .then(res => {
        if (res.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: CREATE_COMPANY, payload: res.data });
          dispatch({ type: HIDE_CREATE_COMPANY_FORM });
          toast.success('Empregador criado');
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const updateCompany = updatedCompany => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.put('companies', updatedCompany)
      .then(res => {
        if (res.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UPDATE_COMPANY, payload: res.data });
          dispatch({ type: HIDE_UPDATE_COMPANY_FORM });
          toast.success('Empregador adicionado');
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const deleteCompany = companyId => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.delete('companies')
      .then(res => {
        if (res.status === 204) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: DELETE_COMPANY, payload: companyId });
          dispatch({ type: HIDE_DELETE_COMPANY_DIALOG });
          toast.success('Empregador excluído');
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const checkDuplicatedCnpj = (company, isCreatingNewCompany) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`companies/checkDuplicatedCnpj?cnpj=${company.cnpj}`)
      .then(res => {
        if (res.status == 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: CHECK_DUPLICATED_CNPJ, payload: { data: res.data, company: company, isCreatingNewCompany: isCreatingNewCompany } });
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const showUpdateCompanyForm = companyId => {
  return {
    type: SHOW_UPDATE_COMPANY_FORM,
    payload: companyId,
  };
};

export const showCreateCompanyForm = () => {
  return { type: SHOW_CREATE_COMPANY_FORM };
};

export const showDuplicatedCnpjDialog = () => {
  return { type: SHOW_DUPLICATED_CNPJ_DIALOG };
};

export const showDeleteCompanyDialog = companyId => {
  return {
    type: SHOW_DELETE_COMPANY_DIALOG,
    payload: companyId,
  };
};

export const hideUpdateCompanyForm = () => {
  return { type: HIDE_UPDATE_COMPANY_FORM };
};

export const hideCreateCompanyForm = () => {
  return { type: HIDE_CREATE_COMPANY_FORM };
};

export const hideDeleteCompanyDialog = () => {
  return { type: HIDE_DELETE_COMPANY_DIALOG };
};

export const hideDuplicatedCnpjDialog = () => {
  return { type: HIDE_DUPLICATED_CNPJ_DIALOG };
};

export const getCompanySummary = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get('dashboard/company/summary')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_COMPANY_SUMMARY, payload: data });
        } else {
          console.log('Error****: null data');
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(function (error) {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getTopUpInvoicesCountByStatus = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get('dashboard/top-up-invoices/count-by-status')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_TOP_UP_INVOICES_COUNT_BY_STATUS, payload: data });
        } else {
          console.log('Error****: null data');
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(function (error) {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const getTopUpInvoicesCountByDayAndStatus = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get('dashboard/top-up-invoices/count-by-day-and-status')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_TOP_UP_INVOICES_COUNT_BY_DAY_AND_STATUS, payload: data });
        } else {
          console.log('Error****: null data');
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(function (error) {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};
