import {
  SIGN_OUT,
  GET_CREDITS,
  VOID_TOP_UP_TRANSACTION,
  SHOW_VOID_TOP_UP_TRANSACTION_DIALOG,
  HIDE_VOID_TOP_UP_TRANSACTION_DIALOG,
} from "../constants/ActionTypes";
import EvomUtils from 'util/EvomUtils';

const INIT_STATE = {
  credits: {
    data: [],
    count: 0
  },
  topUpTransactionVoiding: {
    isVoidingTransaction: false,
    currentVoidingTransaction: null
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_CREDITS: {
      return {
        ...state,
        credits: action.payload,
      };
    }

    case VOID_TOP_UP_TRANSACTION: {
      return state;
    }

    case SHOW_VOID_TOP_UP_TRANSACTION_DIALOG: {
      const transactionUuid = action.payload;
      const currentVoidingTransaction = state.credits.data.find(transaction => transaction.uuid === transactionUuid);

      return {
        ...state,
        topUpTransactionVoiding: {
          isVoidingTransaction: true,
          currentVoidingTransaction: currentVoidingTransaction
        }
      }
    }

    case HIDE_VOID_TOP_UP_TRANSACTION_DIALOG: {
      
      return {
        ...state,
        topUpTransactionVoiding: {
          isVoidingTransaction: false,
          currentVoidingTransaction: null
        }
      }
    }

    default:
      return state;
  }
}
